@import 'contrib/normalize.css';
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

*,
::after,
::before {
  box-sizing: border-box;
}

html {
  font-size: 16px;
}

body {
  @apply bg-white;
  font-size: 100%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

a {
  @apply no-underline;
}

ul {
  list-style: none;
  margin: 0 0 0 0;
  padding: 0;
}

/* This is needed to reverse the CSS normalisation Tailwind applies. Ideally we can remove this
    pretty soon though.
*/
img,
svg,
video,
canvas,
audio,
iframe,
embed,
object {
  display: inline;
}

@layer base {
  h1 {
    @apply text-xl;
  }
  h2 {
    @apply text-base;
  }
  button {
    @apply text-xs;
    @apply font-bold;
  }
  body {
    @apply text-sm;
  }
  caption {
    @apply text-xs;
  }
}
